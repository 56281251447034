import { useTranslation } from 'react-i18next';

// Styles
import styles from './index.module.sass';

// Images
import MEXICO from '../../assets/mexico-flag.png';
import USA from '../../assets/usa-flag.png';
import LOGO from '../../imgs/logo_header.png';
import World from '../../assets/worldfull.svg';

export default function Home({ screenSize }) {
  const { t } = useTranslation('home');

  function onClick(url) {
    window.open(url, '_self');
  }
  return (
    <div className={`${styles.Home} ${styles[screenSize]}`}>
      <div className={styles.header}>
        <img className={styles.imglogo} src={LOGO} alt='Terramar Brands logo' />
      </div>
      <div className={styles.content}>
        <div className={styles.imageBackground}>
          <img src={World} className={styles.world} alt='golden world map' />
        </div>
        <div className={styles.titleContainer}>
          {screenSize !== 'phone' && <div className={styles.title}>{t('title')}</div>}
          {screenSize === 'phone' && (
            <>
              <div className={styles.title}>{t('title1')}</div>
              <div className={styles.title}>{t('title2')}</div>
            </>
          )}
        </div>

        <div className={styles.flags}>
          <div
            className={styles.flag}
            onClick={() => onClick('https://www.terramarbrands.com.mx/')}>
            <div className={styles.flagname}>
              <img className={styles.imgflag} src={MEXICO} alt='México flag' />
              {screenSize === 'phone' && <div className={styles.countryName}>México</div>}
            </div>
            {screenSize === 'phone' && <i className={`material-icons `}>navigate_next</i>}
          </div>
          <div className={styles.flag} onClick={() => onClick('https://terramarbrandsusa.com/')}>
            <div className={styles.flagname}>
              <img className={styles.imgflag} src={USA} alt='USA flag' />
              {screenSize === 'phone' && <div className={styles.countryName}>United States</div>}
            </div>
            {screenSize === 'phone' && <i className={`material-icons `}>navigate_next</i>}
          </div>
        </div>
      </div>
    </div>
  );
}
