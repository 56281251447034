import { Suspense } from 'react';

// Components
import Home from './Components/Home';

// Hooks
import useDevice from './hooks/useDevice';

function Terramar({ userAgent }) {
  const device = useDevice({ userAgent });
  const { screenSize = '' } = device || {};
  return (
    <div className='App'>
      <Home screenSize={screenSize} />
    </div>
  );
}

function App({ history, userAgent = null }) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Terramar history={history} userAgent={userAgent} />
    </Suspense>
  );
}

export default App;
